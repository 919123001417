import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import Button from '~components/blocks/Button'
import useSiteSettings from '~utils/useSiteSettings'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const CTASection = ({ className, title, link, image, buttonText, text }) => {

  const { mobile } = breakpoints
	const [hovered, setHovered] = useState(true)

  const { cta } = useSiteSettings()

  return (
		<div 
		// onMouseEnter={() => setHovered(true)}
		// onMouseLeave={() => setHovered(false)}
		>
			<Section
				className={className}
			>
				<div css={css`
					grid-column: span 6;
					${mobile}{
						grid-column: span 12;  
					}
				`}>
					<h4 css={css`
						margin-bottom: 28px;
					`}>{title ?? cta.title}</h4>
					{(text || cta.text) &&
						<SlideDown css={css`
								transition-duration: 0.35s;
								${mobile}{
									display: none;
								}
							`}>
								{hovered &&
									<p css={css`
										padding-bottom: 34px;
										max-width: 500px;
									`}>{text ?? cta.text}</p>
								}
						</SlideDown>
					}
					<Button 
						to={link ?? resolveLink(cta.link?.to?._type, cta.link?.to?.slug?.current)}
						css={css`
						display: block;
						${mobile}{
							display: none;
						}
				`}>{buttonText ?? cta.link?.linkText}</Button>
				</div>
				<Link 
					to={link ?? resolveLink(cta.link?.to?._type, cta.link?.to?.slug?.current)}
					css={css`
						grid-column: span 6;
						overflow: hidden;
						${mobile}{
							grid-column: span 12;
							margin-bottom: 16px; 
						}
				`}>
					<div css={css`
						transform: scale(1);
						transition: 0.4s transform;
						height: 100%;
						&:hover{
							transform: scale(1.03);
						}
					`}>
						<Image
							asset={image ?? cta.image.asset}
							aspectRatio={1.58}
							css={css`
								/* height: 100%; */
								.gatsby-image-wrapper {
									height: 100%;
								}
							`}
						/>
					</div>
				</Link>
				<Button
					to={link ?? resolveLink(cta.link?.to?._type, cta.link?.to?.slug?.current)}
					css={css`
						display: none;
						${mobile}{
							display: block;
							grid-column: span 12;
						}
					`}>
						{buttonText ?? cta.link?.linkText}
				</Button>
			</Section>
		</div>
  )
}

CTASection.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
}

// Had to remove default props to make sure that this section was defaulting to what's been defined in the site settings

// CTASection.defaultProps = {
//   title: `styling services`,
//   link: `/`, 
//   buttonText: `Enquire Now`
// }

export default CTASection
